import React from 'react';
import fetch from 'isomorphic-fetch';
import Img from 'gatsby-image';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Layout, SEO } from '../components';
//
import './contact.scss';
//
import IconLocation from '../assets/svg/icon-location.svg';
import IconEnvelope from '../assets/svg/icon-envelope.svg';
import IconPhone from '../assets/svg/icon-phone.svg';
import IconFacebook from '../assets/svg/icon-facebook.svg';
import IconSkype from '../assets/svg/icon-skype.svg';
import IconInstagram from '../assets/svg/icon-instagram.svg';

const Contact = () => {
  const data = useStaticQuery(graphql`
    query ContactUs {
      file(relativePath: { eq: "contact-map.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            srcWebp
            srcSetWebp
          }
        }
      }
      sanityContactMetadata {
        metadata {
          title
          keywords
          description
          image {
            asset {
              url
            }
          }
        }
      }
    }
  `);

  const { metadata } = data.sanityContactMetadata;

  return (
    <Layout>
      <SEO path="/contact" {...metadata} />
      <div className="contact-page">
        <section className="section-header container">
          <h1 className="section-title n-b-m n-t-m text-black small">
            Contact JP Superbikes Superstore and our Service Center
          </h1>
          <ul className="bread-crumbs">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li className="current-bread-crumb">Gallery</li>
          </ul>
        </section>
        <hr />
        <div className="container">
          <section className="main-content">
            <div className="details">
              <h5 className="text-black n-b-m">Contact Details</h5>
              <hr />
              <h5 className="text-primary-color small">Sales Department</h5>
              <ul>
                <li className="contact-location">
                  <IconLocation />
                  <div>
                    <p className="adress">Address:</p>
                    <p className="n-b-m pailed-text">19800 Veterans Blvd, Unit C9.</p>
                    <p className="pailed-text">Port Charlotte, FL 33954</p>
                  </div>
                </li>
                <li className="contact-questions">
                  <IconEnvelope />
                  <div>
                    <p className="questions">Have any questions?</p>
                    <p className="pailed-text">jpsuperbikes@comcast.net</p>
                  </div>
                </li>
                <li className="contact-call">
                  <IconPhone />
                  <div>
                    <p className="call-hire">Call us & Hire us.</p>
                    <p className="pailed-text"> (941) 883-6363</p>
                  </div>
                </li>
                <li>
                  <a
                    className="text-primary-color"
                    href="https://goo.gl/maps/1XdL1sEGSHzK3vAB8?coh=178571&entry=tt"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Find JP Superbikes Superstore On Map
                  </a>
                </li>
              </ul>
              <hr className="contact-divider" />
              <p className="contact-social">
                We Are Social:
{' '}
                <a href="https://www.facebook.com/Jpsuperbikes-LLC-169922166402234/">
                  <IconFacebook />
                </a>
                <a href="skype:joca.liza?call">
                  <IconSkype />
                </a>
                <a href="https://www.linkedin.com/in/joca-pavlovic-365b8814a/">
                  <IconInstagram />
                </a>
              </p>
            </div>
            <div className="message-us-content">
              <h5 className="text-black n-b-m">Send Message Us</h5>
              <hr />
              <h5 className="text-primary-color small">I would like to discuss:</h5>
              <form className="form-grid" method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
                <input required type="hidden" name="bot-field" />
                <input required type="hidden" name="form-name" value="contact" />
                <input required type="text" placeholder="Subject you want to talk about" name="subject" />
                <input required type="text" placeholder="Your Name *" name="name" />
                <input required type="email" placeholder="Your Email *" name="email" />
                <input required type="text" placeholder="Phone Number *" name="phone" />
                <textarea required placeholder="Your Message" name="message" />
                <input type="submit" name="sendMail" value="Send us an email" />
              </form>
            </div>
          </section>
        </div>
        {/* <Img className="g-map" fluid={data.file.childImageSharp.fluid} alt="google map image" /> */}
      </div>
    </Layout>
  );
};

export default Contact;
